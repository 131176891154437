<template>
  <v-data-table :headers="headers" :items="items" class="elevation-2" hide-default-footer>
    <template v-slot:item="{ headers }">
      <tr class="">
        <td class="text-center" v-for="(h, key) in headers" :key="key">
          <v-skeleton-loader type="table-cell" width="100px" class="mx-auto"></v-skeleton-loader>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'table-skeleton',
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    let emptyItems = []
    for (let u = 0; u < 10; u++) {
      emptyItems.push({})
    }
    return {
      items: emptyItems,
    }
  },
}
</script>

<style lang="css"></style>

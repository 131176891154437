<template>
  <v-card class="mt-4">
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="tableData.nameOrCode"
        append-icon="mdi-magnify"
        label="Busqueda"
        single-line
        hide-details
        class="mr-4"
        v-on:keyup.enter="searchByForm"
      ></v-text-field>
      <v-btn small class="botonBusqueda mt-4" @click="searchByForm">
        <v-icon>
          {{ icons.mdiClipboardSearch }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-data-table
      v-show="!loading"
      :headers="headers"
      :items="products"
      item-key="id"
      class="elevation-1"
      calculate-widths
      hide-default-footer
      disable-pagination
    >
      <template v-slot:[`item.thumbnail`]="{ item }">
        <v-img
          v-if="item.thumbnail"
          :src="`${storagePrivateLinkApi(item.thumbnail)}`"
          max-height="100px"
          max-width="100px"
          contain
          :aspect-ratio="1"
        ></v-img>
        <v-img v-else :src="getDefaultImage" max-height="100px" max-width="100px" contain :aspect-ratio="1"></v-img>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-dialog max-width="500px" transition="dialog-transition" scrollable>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small color="primary" v-on="on" v-bind="attrs">
              <v-icon>
                {{ icons.mdiEyePlus }}
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:default="d">
            <v-card max-width="100%">
              <v-card-text>
                <p>Fecha: {{ formatDate(item.created_at) }}</p>
                <p>CEDIS: {{ item.cedis_name }} ({{ item.cedis_code }})</p>
                <p>Proveedor: Gullermo S.A.</p>
                <p>Pallets: 1</p>
                <p>Cajas: {{ item.number_of_boxes }}</p>
                <p>Cajas por pallet: 20</p>
                <p>Caducidad: {{ item.expiration }}</p>
                <p>Más imágenes:</p>
                <v-row>
                  <v-col cols="4" v-for="(img, key) in item.images" :key="key">
                    <v-img
                      :src="storagePrivateLinkApi(img)"
                      contain
                      :aspect-ratio="1"
                      max-width="100%"
                      max-height="100%"
                    ></v-img>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="d.value = false" class="mt-3">
                  Ok
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </template>
      <template v-slot:footer>
        <div class="pt-5 px-3 d-flex">
          <div style="width: 100px;">
            <v-select
              v-model="tableData.perPage"
              :items="[5, 10, 20, 30, 40, 50]"
              dense
              hide-details
              class="mt-2"
              outlined
            ></v-select>
          </div>
          <v-spacer></v-spacer>
          <p class="mt-2 mr-3">
            <v-btn icon v-if="tableData.page > 1" @click="tableData.page--">
              <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
            </v-btn>
            {{ tableData.page }}
            <v-btn icon @click="tableData.page++" :disabled="!(products.length > 0)">
              <v-icon>{{ icons.mdiChevronRight }}</v-icon>
            </v-btn>
          </p>
        </div>
      </template>
    </v-data-table>
    <tabla-skeleton v-show="loading" :headers="headers"></tabla-skeleton>
  </v-card>
</template>

<script>
import TablaSkeleton from './table-skeleton.vue'
import _ from 'lodash'
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
  mdiFormSelect,
  mdiClipboardSearch,
  mdiEyePlus,
  mdiChevronLeft,
  mdiChevronRight,
} from '@mdi/js'
import helpers from '../../mixins/helpers'

export default {
  components: { 'tabla-skeleton': TablaSkeleton },
  mixins: [helpers],
  data() {
    return {
      expanded: [],
      loading: false,
      headers: [
        {
          text: 'IMÁGEN',
          value: 'thumbnail',
          align: 'start',
          sortable: false,
          width: '20%',
        },
        {
          text: 'CÓDIGO',
          value: 'assigned_code',
          sortable: false,
          width: '20%',
        },
        {
          text: 'NOMBRE',
          value: 'name',
          width: '20%',
        },
        {
          text: 'DESCRIPCIÓN',
          value: 'description',
          width: '40%',
        },
        {
          text: 'VER MÁS',
          value: 'actions',
          sortable: false,
        },
      ],
      products: [],
      tableData: {
        nameOrCode: '',
        page: 1,
        perPage: 10,
      },
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
        mdiFormSelect,
        mdiClipboardSearch,
        mdiEyePlus,
        mdiChevronLeft,
        mdiChevronRight,
      },
    }
  },
  mounted() {
    this.searchProducts()
  },
  methods: {
    searchProducts() {
      this.tableData.loading = true
      this.products = []
      let url = this.$base_api_url + `/getProductsForCatalogueClients`

      let data = {
        nameOrCode: this.tableData.nameOrCode,
        perPage: this.tableData.perPage,
        page: this.tableData.page,
      }
      this.$http
        .post(url, data)
        .then(res => {
          this.products = this.$_.map(res.data.data, item => {
            return {
              ...item,
            }
          })
        })
        .catch(res => {
          this.catchResponse(res)
        })
        .then(() => {
          this.tableData.loading = false
        })
    },
    searchByForm() {
      //Al momento de actualizar la pagina, se actualiza el contenido, si la pagina es la primera entonces ejecutamos la busqueda
      if (this.tableData.page == 1) {
        this.searchProducts()
      }
      this.tableData.page = 1
    },
    debounceSearchProducts: _.debounce(function() {
      this.searchProducts()
    }, 500),
  },
  watch: {
    'tableData.perPage': function(newValue, oldValue) {
      if (newValue != oldValue) {
        this.searchProducts()
      }
    },
    'tableData.page': function(newValue, oldValue) {
      if (newValue != oldValue) {
        this.debounceSearchProducts()
      }
    },
  },
}
</script>
<style>
.botonBusqueda {
  border: 1px solid rgba(255, 255, 255, 0.253);
}
.verMas {
  text-align: center;
}
</style>

<template>
  <div>
    <cabecera></cabecera>
    <productos></productos>
  </div>
</template>

<script>
import cabecera from './Cabecera.vue'
import productos from './Productos.vue'

export default {
  components: {
    cabecera,
    productos,
  },
}
</script>
